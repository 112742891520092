import readXlsxFile from 'read-excel-file';
import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import moment from 'moment';
import { validateFileSize, validExtension } from '@/helpers/validationList';
import { isNotEmpty, hasExtension, hasTitles } from '@/helpers/validationList.js';

export default {
	name: 'ImportMassiveDialog',
	data() {
		return {
			mode: 'FORM',
			selectedFile: null,
			importMassiveForm: {
				selectedFile: null,
			},
			fileRule: [
				(v) => !!v || 'Campo requerido',
				(v) =>
					validExtension(v, ['.xslx', '.xlsx']) ||
					'La extensión del archivo no es válida',
				(v) =>
					validateFileSize(v, 10) ||
					'"El archivo es demasiado grande. Máximo 10 MB',
			],
			dialog: false,
			listError: [],
			valid: false,
			loading: false,
			loadingSave: false,
			filelist: [],
			savedAssets: [],
			headers: [
				{ text: 'Tipo de activo', sortable: false, value: 'asset_type' },
				{ text: 'Activo fijo', sortable: false, value: 'af' },
				{ text: 'Etiqueta', sortable: false, value: 'tag' },
				{ text: 'Equipo modelo', sortable: false, value: 'equipment_model' },
				{ text: 'Número de serie', sortable: false, value: 'serial_number' },
				{ text: 'PEP', sortable: false, value: 'pep' },
				{ text: 'Servicio', sortable: false, value: 'service' },
				{ text: 'Nombre', sortable: false, value: 'name' },
				{ text: 'Orden de compra', sortable: false, value: 'oc' },
				{ text: 'Observaciones', sortable: false, value: 'observations' },
				{
					text: 'Valor de adquisición',
					sortable: false,
					value: 'purchase_value',
				},
				{ text: 'Fecha de compra', sortable: false, value: 'purchase_date' },

				// { text: "Área", sortable: false, value: "area" },
				// { text: "Número de activo", sortable: false, value: "num_asset" },
			],

			headers2: [
				// { text: "Nombre", sortable: false, value: "asset.name" },
				// { text: "Número de activo", sortable: false, value: "asset.num_asset" },
				// { text: "Tipo", sortable: false, value: "asset.asset_type.name" },
				// { text: "Area", sortable: false, value: "asset.area.name" },
				// { text: "Serie", sortable: false, value: "asset.serial_number" },
				// {
				//   text: "Valor de adquisición",
				//   sortable: false,
				//   value: "asset.purchase_value",
				// },
				// { text: "Tipo de activo", sortable: false, value: "asset.asset_type" },
				{ text: 'Activo fijo', sortable: false, value: 'asset.af' },
				{ text: 'Etiqueta', sortable: false, value: 'asset.tag' },
				{
					text: 'Equipo modelo',
					sortable: false,
					value: 'asset.equipment_model',
				},
				{
					text: 'Número de serie',
					sortable: false,
					value: 'asset.serial_number',
				},
				{ text: 'PEP', sortable: false, value: 'asset.pep' },
				{ text: 'Servicio', sortable: false, value: 'asset.service' },
				{ text: 'Nombre', sortable: false, value: 'asset.name' },
				{ text: 'Orden de compra', sortable: false, value: 'asset.oc' },
				{ text: 'Observaciones', sortable: false, value: 'asset.observations' },
				{
					text: 'Valor de adquisición',
					sortable: false,
					value: 'asset.purchase_value',
				},
				{
					text: 'Fecha de compra',
					sortable: false,
					value: 'asset.purchase_date',
				},

				{ text: 'Estado', sortable: false, value: 'messages' },
			],
		};
	},
	computed: {
		dialogWidth() {
			return this.mode !== 'FORM' ? 1200 : 500;
		},
		filteredHeaders() {
			return this.headers.filter((header) => header.value !== 'status');
		},
	},
	methods: {
		...mapActions('asset', ['createAssetMultiple', 'downloadAssetFormat']),
		dialogInput(value) {
			this.$refs.button.$el.blur();
			this.dialog = value;
			this.importMassiveForm = {
				selectedFile: null,
			};
			this.$refs?.form?.resetValidation();
		},

		getMessage(success) {
			return success
				? { status: 'GUARDADO', color: 'green' }
				: { status: 'NO GUARDADO', color: 'red' };
		},

		// async changeFile() {
		//   this.filelist = [];
		//   try {
		//     const file = this.importMassiveForm.selectedFile;

		//     if (hasExtension(file, [".xlsx"])) {
		//       const rows = await readXlsxFile(file);

		//       const titles = [
		//         'TIPO DE ACTIVO',
		//         'ACTIVO FIJO',
		//         'ETIQUETA',
		//         'EQUIPO MODELO',
		//         'NUMERO DE SERIE',
		//         'CECO/PEP',
		//         'AREA/SERVICIO',
		//         'NOMBRE EQUIPO',
		//         'OC',
		//         'OBSERVACIONES',
		//         'VALOR DE ADQUISICION',
		//         'FECHA DE COMPRA',
		//         'VIDA UTIL',
		//         'COSTO MANENIMIENTO',
		//         'OTROS COSTOS',
		//       ]

		//       // const titles = [
		//       //   "Nombre",
		//       //   "Número de serie",
		//       //   "Tipo de Activo",
		//       //   "Área",
		//       //   "Servicio",
		//       //   "Nro. Activo",
		//       //   "CECO/PEP",
		//       //   "Etiqueta",
		//       //   "Observaciones",
		//       //   "Valor de adquisición",
		//       //   "Fecha de compra",
		//       //   "Vida util",
		//       //   "Costo mant.",
		//       //   "Otros costos",
		//       //   "Equipo modelo",
		//       //   "OC",
		//       //   "AF",
		//       //   "Tipo de equipo",
		//       //   "Monitor modelo",
		//       //   "Monitor marca",
		//       //   "Subnumero"
		//       // ];

		//       if (isNotEmpty(rows) && hasTitles(rows, titles)) {
		//         let hasError = false;

		//         rows.forEach((row, index) => {
		//           if (index > 0 && row[1] && row[4]) {

		//             if (row[2] && row[6] && row[4]) {

		//               this.filelist.push({
		//                 asset_type: row[0] ? row[0] : null,
		//                 af: row[1] ? row[1].toString() : null,
		//                 tag: row[2] ? row[2].toString() : null,
		//                 equipment_model: row[3] ? row[3] : null,
		//                 serial_number: row[4]?.toString(),
		//                 pep: row[5] ? row[5] : null,
		//                 service:row[6],
		//                 name: row[7] ? row[7]: null,
		//                 oc: row[8] ? row[8] : null,
		//                 observations: row[9] ? row[9].toString() : null,
		//                 purchase_value: row[10] ? row[10] : null,
		//                 purchase_date: row[11] ? row[11] : null,
		//                 useful_life: row[12] ? row[12] : null,
		//                 maintenance_cost: row[13] ? row[13] : null,
		//                 other_cost: row[14] ? row[13] : null,
		//               })

		//               // this.filelist.push({
		//               //   name: row[0] ? row[0]: null,
		//               //   serial_number: row[1]?.toString(),
		//               //   asset_type: row[2] ? row[2] : null,
		//               //   area: row[3],
		//               //   service:row[4],
		//               //   num_asset: row[5] ? row[5]?.toString() : null,
		//               //   pep: row[6] ? row[6] : null,
		//               //   tag: row[7] ? row[7].toString() : null,
		//               //   observations: row[8] ? row[8].toString() : null,
		//               //   purchase_value: row[9] ? row[9] : null,
		//               //   purchase_date: row[10] ? row[10] : null,
		//               //   useful_life: row[11] ? row[11] : null,
		//               //   maintenance_cost: row[12] ? row[12] : null,
		//               //   other_cost: row[13] ? row[13] : null,
		//               //   equipment_model: row[14] ? row[14] : null,
		//               //   oc: row[15] ? row[15] : null,
		//               //   af: row[16] ? row[16].toString() : null,
		//               //   equipment_type: row[17] ? row[17] : null,
		//               //   monitor_model: row[18] ? row[18] : null,
		//               //   monitor_brand: row[19] ? row[19] : null,
		//               //   sub_numero: row[20] ? row[20] : null,
		//               // });
		//             }
		//           }
		//         });

		//         if (!hasError) {
		//           this.mode = "UPLOAD";
		//         } else {
		//           this.mode = "FORM";
		//         }
		//       } else {
		//         this.mode = "FORM";
		//       }
		//     }
		//   } catch (e) {
		//     $EventBus.$emit(
		//       "showSnack",
		//       "error",
		//       e || "Ocurrio un error al leer el archivo."
		//     );
		//     this.mode = "FORM";
		//   }
		// },

		async changeFile() {
			this.filelist = [];
			try {
				const file = this.importMassiveForm.selectedFile;

				if (hasExtension(file, ['.xlsx'])) {
					const rows = await readXlsxFile(file);

					const titles = [
						'TIPO DE ACTIVO',
						'ACTIVO FIJO (AF)',
						'ETIQUETA',
						'EQUIPO MODELO',
						'NUMERO DE SERIE',
						'CECO/PEP',
						'AREA/SERVICIO',
						'NOMBRE EQUIPO',
						'ORDEN DE COMPRA (OC)',
						'OBSERVACIONES',
						'VALOR DE ADQUISICION',
						'FECHA DE COMPRA',
						'VIDA UTIL',
						'COSTO MANTENIMIENTO',
						'OTROS COSTOS',
					];

					if (isNotEmpty(rows) && hasTitles(rows, titles)) {
						let hasError = false;

						rows.forEach((row, index) => {
							if (index === 0) return;

							const af = row[1] ? row[1].toString() : '';
							const asset_type = row[0];
							const pep = row[5];
							const service = row[6];

							if (!asset_type || !af || !pep || !service) {
								hasError = true;
								$EventBus.$emit(
									'showSnack',
									'error',
									`Fila ${
										index + 1
									}: Faltan datos obligatorios en el activo AF: ${
										af || 'sin AF'
									}`
								);
								return;
							}

							this.filelist.push({
								asset_type: asset_type || null,
								af,
								tag: row[2] ? row[2].toString() : null,
								equipment_model: row[3] || null,
								serial_number: row[4]?.toString() || null,
								pep,
								service,
								name: row[7] || null,
								oc: row[8] || '-',
								observations: row[9]?.toString() || null,
								purchase_value: row[10] || null,
								purchase_date: row[11] || null,
								useful_life: row[12] || null,
								maintenance_cost: row[13] || null,
								other_cost: row[14] || null,
							});
						});

						this.mode = hasError ? 'FORM' : 'UPLOAD';
					} else {
						this.mode = 'FORM';
					}
				}
			} catch (e) {
				$EventBus.$emit(
					'showSnack',
					'error',
					e || 'Ocurrió un error al leer el archivo.'
				);
				this.mode = 'FORM';
			}
		},

		async save() {
			this.loadingSave = true;
			const { ok, error, response } = await this.createAssetMultiple(this.filelist);
			if (ok) {
				const assets = response;
				this.savedAssets = Array.isArray(assets) ? assets : [];

				this.mode = 'SAVED';
				await this.$emit('getAssets');

				this.loadingSave = false;
			} else {
				let errorMsm = error?.data?.message;
				errorMsm = Array.isArray(errorMsm) ? errorMsm.join(', ') : errorMsm;
				$EventBus.$emit('showSnack', 'error', errorMsm);
				this.loadingSave = false;
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY');
		},

		async downloadTemplate() {
			try {
				this.loading = true;
				const { ok, error } = await this.downloadAssetFormat();
				if (!ok) {
					console.error(error);
				}
				this.loading = false;
			} catch (ex) {
				console.error('Hubo un error al descargar el modelo:', ex);
			}
		},

		close() {
			this.dialog = false;
			this.mode = 'FORM';
			this.importMassiveForm = {
				selectedFile: null,
			};
			this.savedAssets = [];
			this.$refs?.form?.resetValidation();
		},
	},
};
